<template>
  <b-card>
    <!-- create table contain all guardians defined and opertaions allowed -->
    <g-table
      ref="guardians-table"
      :items="items"
      :columns="tableColumns"
      :is-busy="isTableBusy"
      :noAction="true"
      perPage="25"
      :totalRows="totalRows"
      @filtered="onFiltered"
      :createButton="{ visiable: true , permission: 'addGuardians'}"
      :searchInput="{ visiable: true }"
      @on-create="
        (v) => {
          $router.push({ name: 'guardian-new' });
        }
      "
    >
      <template #actions="{ item }">
        <div class="text-nowrap">
          <!-- define edit button -->
          <b-button
            v-b-tooltip.hover.top="$t('edit')"
            v-permission="'editGuardians'"
            data-action-type="edit"
            variant="custom"
            class="btn-icon"
            size="sm"
            @click="editGuardian(item)"
          >
            <feather-icon
              :id="`invoice-row-${item.id}-preview-icon`"
              icon="EditIcon"
              class="mx-25 clickable"
            />
          </b-button>

          <!-- define delete button -->
          <b-button
            v-b-tooltip.hover.top="$t('delete')"
            v-permission="'deleteGuardians'"
            data-action-type="delete"
            variant="flat-danger"
            class="btn-icon"
            size="sm"
            @click="remove(item)"
          >
            <feather-icon
              :id="`invoice-row-${item.id}-delete-icon`"
              icon="TrashIcon"
              stroke="red"
              class="danger"
            />
          </b-button>
        </div>
      </template>
    </g-table>
  </b-card>
</template>

<script>
import GTable from '@/pages/Shared/Table.vue';
import { rows } from '@/libs/acl/Lookups';

export default {
  components: {
    GTable,
  },

  data() {
    return {
      items: [],
      totalRows: 0,
      currentPage: 1,
      perPage: 25,
      rows: rows,
      modal: false,
      isTableBusy: false,
      filter: {
        orderClause: null,
      },
    };
  },
  computed: {
    tableColumns() {
      return [
        { key: 'code', label: this.$t('code'), sortable: true },
        {
          key: this.isRight ? 'arabicName' : 'englishName',
          field: this.isRight ? 'arabicName' : 'englishName',
          label: this.$t('name'),
          sortable: true,
        },
        { key: 'mobileNumber', label: this.$t('Phone'), sortable: true },
        {
          key: this.isRight ? 'nationalityNameAr' : 'nationalityNameEn',
          field: this.isRight ? 'nationalityNameAr' : 'nationalityNameEn',
          label: this.$t('row'),
          sortable: true,
        },
        { key: 'actions' },
      ];
    },
  },

  mounted() {
    this.getItems();
  },

  methods: {
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    getItems() {
      this.get({ url: 'Guardians' }).then((data) => {
        this.items = data;
      })
    },

    remove(item) {
      this.confirmAction(
        {
          text: this.$t('areYouSureYouWantToDelete'),
        },
        () => {
          this.delete({ url: 'Guardians', id: item.id }).then(() => {
            this.doneAlert({ text: this.$t('deletedSuccessfully') });
            this.getItems();
          });
        }
      );
    },

    editGuardian(item) {
      this.$router.push({
        name: 'guardian-edit',
        params: { id: item.id },
      });
    }
  },
};
</script>

<style></style>
